import initTest from './test.mock';
import initVenue from './venue.mock';
import initTable from './table.mock';
import userInit from './user.mock';
import initMenu from './menu.mock';
// import initStatistic from './statistic.mock';

export function initMocks() {
  const baseURL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5000';

  initTest();
  initVenue(baseURL);
  initTable(baseURL);
  initMenu(baseURL);
  userInit(baseURL);
  // initStatistic(baseURL);
}
