import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import qs from 'qs';
import { PrivateRoute } from './private-route';
import { ErrorPage } from '../modules/error';
import { VenuePage } from '../modules/management/venue-page.component';
import { MenuPage } from '../modules/menu';

export const MainRouter = () => {
  // document.addEventListener('http-error',
  //     ({ detail }: CustomEventInit) => window.location.href=`/error?${qs.stringify(detail)}`,
  // );

  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/" component={VenuePage} />
        <PrivateRoute exact path="/venue" component={VenuePage} />
        <PrivateRoute exact path="/venue/:venueId" component={MenuPage} />
        <Route component={ErrorPage} path="/error"/>
        <Route path="*">
          <ErrorPage />
        </Route>
      </Switch>
    </Router>
  );
};
