import { ROUTES } from '../../common/constants/index';

/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpServiceFactory } from '../../common/services/index';
import { IVenue } from '../types';
import { EnhancedWithAuthHttpService } from '../../common/services/http-auth.service';

export class VenueService {
  private httpService: EnhancedWithAuthHttpService

  constructor(private httpFactory: HttpServiceFactory) {
    this.httpService = this.httpFactory.createAuthHttpService();
  }

  public getVenues(): Promise<IVenue[] | void> {
    return this.httpService.get<IVenue[]>(ROUTES.ROUTES_VENUES);
  }
}

export const venueService = new VenueService(new HttpServiceFactory());
