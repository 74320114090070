import React, { useState } from "react";
import { useQuery } from "react-query";
import { StyledTable } from "../../../common/components/table";
import { ROUTES } from "../../../common/constants/index";
import { ITableColumn, TableCellType } from "../../../common/types";
import { venueService } from "../../services/venue.service";
import { Styled } from "../venue-management/venue-management.styled";
import { TextField, Box, InputAdornment } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";

export const VenueTable = ({ additionalColumns = [] }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  let { data } = useQuery(ROUTES.ROUTES_VENUES, () => {
    return venueService.getVenues();
  });

  var data_filter = data?.filter((element) =>
    element.name.toLowerCase().includes(searchQuery.trim().toLowerCase())
  );

  const columns: ITableColumn[] = [
    { title: "Name", key: "name", type: TableCellType.Text },
    {
      title: "Description",
      key: "description",
      type: TableCellType.Text,
    },
    {
      title: "Address",
      key: "address",
      type: TableCellType.Text,
    },
    {
      title: "Media",
      key: "media",
      type: TableCellType.Text,
    },
    ...additionalColumns,
  ];

  return (
    <>
      <Styled.Searchbar>
        <Box
          sx={{
            width: 500,
            maxWidth: "100%",
          }}
        >
          <TextField
            fullWidth
            multiline
            id="standard"
            placeholder="What would you like?"
            variant="standard"
            autoComplete="off"
            style={{ backgroundColor: "white",padding:10, borderRadius:10 }}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined style={{ color: "gray" }} />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />
        </Box>
      </Styled.Searchbar>
      <StyledTable
        columns={columns}
        rows={data_filter || data || []}
      ></StyledTable>
    </>
  );
};
