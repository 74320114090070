import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { auth } from '../modules/common/services/auth.service';
import { PrivateRouteProps } from './types';

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...otherProps } = props;

  const getRouteComponent = (routeProps:RouteComponentProps) => {
    const el = auth.checkIsTokenValid() ? <Component {...routeProps} /> : <Component {...routeProps} />;
    return el;
  };

  return (
    <Route
      {...otherProps}
      path={props.path}
      render={getRouteComponent}
    />
  );
};
