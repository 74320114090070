import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Menu } from "./components/menu";
import { Styled } from "./menu-page.styled";
import { ROUTES } from "../common/constants";
import { menuService } from "./services/menu.service";
import defaultImage from "../../assets/default-venue.png";
import { LineSpinFadeLoader } from "@alex_xu/react-loading";

interface IMenuPageParams {
  venueId: string;
}

export const MenuPage = () => {
  const history = useHistory();
  const { venueId } = useParams<IMenuPageParams>();
  const [isLoading, setIsLoading] = useState(true);
  const { data } = useQuery(ROUTES.ROUTES_MENU, () =>
    menuService.getMenu(venueId)
  );
  const [menuData, setMenuData] = useState(data || []);

  useEffect(() => {
    setMenuData(data || []);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [data]);

  const back = () => {
    history.push("/");
  };

  return (
    <div>
      {isLoading ? (
        <Styled.CenteredWrapper>
          <LineSpinFadeLoader color="#E42121" />
        </Styled.CenteredWrapper>
      ) : (
        <Styled.ContentWrapper>
          <div
            style={{
              height: "30vh",
              width: "100%",
              backgroundImage: `url(${
                menuData[0]?.venue.media || defaultImage
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              borderBottomLeftRadius: 60,
              borderBottomRightRadius: 60,
            }}
          />
          <Styled.TopButtonsWrapper>
            <Styled.BackButton onClick={back} />
          </Styled.TopButtonsWrapper>
          <Menu data={menuData!} venueId={venueId} />
        </Styled.ContentWrapper>
      )}
    </div>
  );
};
