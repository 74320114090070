import React from "react";
import { CardWrapper, CardContainer,Address } from "../table/table.styled";
import { IMap, ITableColumn } from "../../types";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
} from "@material-ui/core";
import defaultFood from "../../../../assets/default-venue.png";
import { Link } from "react-router-dom";

export interface IStyledTableProps {
  columns: ITableColumn[];
  rows: IMap[];
  keyProp?: string;
}

export const StyledTable: React.FC<IStyledTableProps> = ({
  columns,
  rows,
  keyProp = "id",
}) => {
  const row = rows.map((item) => (
    <CardContainer key={item.id}>
      <Link to={`/venue/${item.id}`} style={{ textDecoration: "none" }}>
        <Card
          style={{
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            boxShadow: "none",
          }}
        >
          <CardActionArea style={{ backgroundColor: "#f0f0f0" }}>
            <CardMedia
              component="img"
              height="200"
              image={item.media || defaultFood}
              alt="green iguana"
              style={{
                borderBottomLeftRadius: 14,
                borderBottomRightRadius: 14,
              }}
            />
            <Address>{item.address}</Address>
            <CardContent style={{ paddingTop:12,paddingLeft:0 }}>
              <Typography
                style={{ fontWeight: "500" }}
                gutterBottom
                variant="h5"
                component="div"
              >
                {item.name}
              </Typography>
              <Typography variant="body1" style={{ fontWeight: 300 }}>
                {item.description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </CardContainer>
  ));
  return <CardWrapper>{row}</CardWrapper>;
};
