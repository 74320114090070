import { makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { colorPalette } from '../../../../styles/color-palette';
import { spaces } from '../../../../styles/spaces';

interface StyleProps {
  isOpened?: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>(() => ({
  accordion: {
    border: ({ isOpened }) => isOpened ? `1px solid ${colorPalette.black}` : 'none',
    marginBottom: spaces.xxs,
  },
  summary: {
    background: colorPalette.secondary_grey,
    cursor: 'default !important',
  },
  details: {
    flexDirection: 'column',
  },
}));

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Wrapper = styled.div`
  align-items: center;
`;

const Category = styled.h5`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  white-space:nowrap;
  border:1px solid lightgray;
  padding:12px 20px;
  border-radius:8px;
  margin:4px;
  color:#727272;
`;

const ItemsCount = styled.span`
  min-width: fit-content;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: ${({ theme }) => theme.fonts.md};
  line-height: 19px;
  letter-spacing: -0.3px;
`;

const ReorderIcon = styled.img`
  margin-right: ${({ theme }) => theme.spaces.sm};
`;

const Icon = styled.img`
  width: 32px;
  height: 32pxl;
`;

const IconWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spaces.xs};
`;

export const Styled = {
  Content,
  Wrapper,
  Category,
  ItemsCount,
  ReorderIcon,
  IconWrapper,
  Icon,
};
