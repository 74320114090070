import React, { useState } from "react";
import { Styled, useStyles } from "./category.styled";
import { IDish } from "../../types";
import { Link } from "react-router-dom";

interface ICategoryProps {
  id: string;
  media: string;
  type: string;
  menuItems: IDish[];
  venueId: string;
}

const Category: React.FC<ICategoryProps> = ({ id, type,media }) => {
  return (
    <a href={`#${id}`} style={{ textDecoration: "none" }}>
      <Styled.Category>
        <img width="15" style={{ marginRight:10 }} src={media} alt="" />
        {type}
        </Styled.Category>
    </a>
  );
};

export default Category;
