import React from 'react';
import { MainRouter } from './navigation/router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles';
import { initMocks } from './__mocks__';
import { auth } from './modules/common/services/auth.service';

if (process.env.REACT_APP_MOCK === 'enabled') {
  console.log('Mocks has been enabled!!!');
  initMocks();
}

const queryClient = new QueryClient();

const App = () => {
  // auth.checkIsTokenValid();
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <MainRouter />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
