import React from "react";
import { Styled } from "./venue-management.styled";
import { VenueTable } from "../venue-table";
import mainLogo from "../../../../assets/logo.png";

export const VenueManagement = () => {
  return (
    <Styled.Wrapper>
      <Styled.Centered>
        <img src={mainLogo} width="120" alt="Logo" />
      </Styled.Centered>
      <Styled.ListWrapper>
        <VenueTable />
      </Styled.ListWrapper>
    </Styled.Wrapper>
  );
};
