import styled from 'styled-components';

const Title = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fonts.xl};
  line-height: 28px;
  letter-spacing: -0.3px;
  margin: 0;

  color: ${({ theme }) => theme.colors.black};
`;

const HomeWrapper = styled.div`
  padding: 10px 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AccordionWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.sm};
  display:flex;
  overflow-x:scroll;
`;

const MenuItemContainer = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
@media (min-width: 1200px) {
  grid-template-columns: repeat(auto-fit, minmax(320px, calc(25% - 0.5rem)));
}
`;

const MenuItem = styled.div`
display:flex;
flexDirection:row;
border:1px solid lightgray;
margin-right:14px;
margin-top:8px;
border-radius:4px;
padding-bottom:6px;
@media (max-width: 768px) {
  flex-basis: 100%;
}
`;

export const Styled = {
  Title,
  ButtonWrapper,
  HomeWrapper,
  AccordionWrapper,
  MenuItemContainer,
  MenuItem
};
