import styled from 'styled-components';
import arrowLeft from '../../../../assets/arrow-left.png';

const Button = styled.button`
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.white};
  border-radius: 7px;
  margin-top: 30px;
  margin-right: 50px;
  margin-left: auto;
  max-width: 150px;
  min-width: 150px;
  height: 45px;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Centered = styled.div`
margin-left:auto;
margin-right:auto;
margin-top:50px;
`;

const Searchbar = styled.div`
align-items:center;
display:flex;
justify-content:center;
margin-top:14px;
padding:5px 30px;
`;

const ListWrapper = styled.div`
  width: auto;
  height: 100%;
`;

const BackButton = styled.div`
  background-image: url(${arrowLeft});
  height: 40px;
  width: 40px;
  background-size: cover;
  margin: 30px 0px 0px 50px;
  cursor: pointer;
`;

const RoundButton = styled.button`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: flex-end;
  position: absolute;
  right: 30px;
  border: none;
  cursor: pointer;
`;

const TopButtonsWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  margin-top: ${({ theme }) => theme.spaces.lg};
`;

const modalStyles = {
  content: {
    top: '15%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '850px',
    maxHeight: '85%',
    overflowY: 'auto',
    transform: 'translate(-50%, -10%)',
    backgroundColor: '#ffffff',
    borderRadius: '20px',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(128,128,128, 0.5)',
  },
};

export const Styled = {
  Button,
  Wrapper,
  ListWrapper,
  RoundButton,
  BackButton,
  TopButtonsWrapper,
  modalStyles,
  Centered,
  Searchbar
};
