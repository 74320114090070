import React from "react";
import Category from "../category";
import { Styled } from "./menu.styled";
import { ICategory, ICategoryPayload } from "../../types";
import { sortOrderByData } from "../../utils/sortArray";
import defaultFood from "../../../../assets/menu-item.png";

interface IMenuProps {
  data?: ICategory[];
  venueId: string;
}

export const Menu: React.FC<IMenuProps> = ({ data = [], venueId }) => {
  const onSortByDateCreation = () =>
    sortOrderByData(data as ICategory[], "createdAt", "ASC");

  const sortedData = onSortByDateCreation() || [];

  const formatPrice = (price) => {
    return "€" + price.toFixed(2);
  };

  return (
    <Styled.HomeWrapper>
      <Styled.AccordionWrapper>
        {sortedData?.map(({ id, type, media, menuItems }) => (
          <Category
            key={id}
            id={id}
            type={type}
            media={media}
            menuItems={menuItems}
            venueId={venueId}
          />
        ))}
      </Styled.AccordionWrapper>
      {sortedData.map((category) => (
        <div key={category.id} id={category.id} style={{ marginTop: 16 }}>
          {category.menuItems.length > 0 ? (
            <h2 style={{ fontSize: 20, color: "#181818" }}>{category.type}</h2>
          ) : (
            ""
          )}
          <Styled.MenuItemContainer>
            {category.menuItems.length > 0
              ? category.menuItems.map((item) => (
                  <Styled.MenuItem key={item.id}>
                    <div>
                      <img
                        src={item.media || defaultFood}
                        style={{ borderBottomRightRadius: 50 }}
                        width="120"
                        height="110"
                        alt=""
                      />
                    </div>
                    <div style={{ paddingTop: 12, paddingLeft: 14 }}>
                      <h3 style={{ fontSize: 16 }}>
                        <strong>{item.name}</strong>
                      </h3>
                      <p
                        style={{
                          marginTop: 8,
                          fontWeight: 300,
                          fontSize: 14,
                          color: "#727171",
                        }}
                      >
                        {" "}
                        {item.description}
                      </p>
                      <p
                        style={{
                          marginTop: 10,
                          color: "gray",
                          fontWeight: "bold",
                        }}
                      >
                        {formatPrice(item.price)}
                      </p>
                    </div>
                  </Styled.MenuItem>
                ))
              : ""}
          </Styled.MenuItemContainer>
        </div>
      ))}
    </Styled.HomeWrapper>
  );
};
