export interface IMap {
  [key: string]: any
};

export interface ITableColumn {
  title: string,
  key: string,
  type: TableCellType,
  style?: React.CSSProperties,
  onClick?: Function,
  search?:string
}

export enum TableCellType {
  Button,
  Text
};
