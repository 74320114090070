import styled from 'styled-components';
import arrowLeft from '../../assets/newBack.png';
import defaultCover from '../../assets/default-venue.png'

const Wrapper = styled.div`
  display: flex;
  min-width: 1024px;
  height: 100vh;
`;

const CenteredWrapper = styled.div`
  display: flex;
  height: 100vh;
  justify-content:center;
  align-items:center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
`;

const TopButtonsWrapper = styled.div`
position:absolute;
top:25px;
left:20px;
background:white;
padding-right:2px;
border-radius:50px;

`;

const ImageBanner = styled.div`
background-image: url(${defaultCover});
background-size: cover;
width:100%;
height:400px;
border-radius:0px 0px 100px 100px;
`;

const BackButton = styled.div`
  background-image: url(${arrowLeft});
  height: 35px;
  width: 35px;
  background-size: cover;
  cursor: pointer;
`;

const Button = styled.button`
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.white};
  border-radius: 7px;
  margin-top: 30px;
  margin-right: 50px;
  margin-left: auto;
  max-width: 150px;
  min-width: 150px;
  height: 45px;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

const RoundButton = styled.button`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: flex-end;
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  cursor: pointer;
`;

const modalStyles = {
  content: {
    top: '15%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '45%',
    transform: 'translate(-40%, -10%)',
    backgroundColor: '#ffffff',
    borderRadius: '20px',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(128,128,128, 0.5)',
  },
};



export const Styled = {
  Wrapper,
  Button,
  ContentWrapper,
  BackButton,
  TopButtonsWrapper,
  RoundButton,
  modalStyles,
  ImageBanner,
  CenteredWrapper
};
